import { TopLevelFormatterParams } from 'echarts/types/dist/shared.js'
import { TFunction } from 'i18next'
import { formatISO_, formatToSingleDecimalIfNecessary } from './utility-fns'

export const generateBarcodeSVG = (rowsStartNumber: number, rowsEndNumber: number, rowsScanned: number[]) => {
  const width = 220
  const range = rowsEndNumber - rowsStartNumber
  const normalisedRowsLength = range > 50 ? 50 : range
  const rowHeight = 30

  const normalisedRows = new Array(normalisedRowsLength).fill(false)

  rowsScanned.forEach((row) => {
    const normalizedRow = Math.floor(((row - rowsStartNumber) / range) * (normalisedRowsLength - 1))
    normalisedRows[normalizedRow] = true
  })

  const rowWidth = width / normalisedRowsLength
  const svgNamespace = 'http://www.w3.org/2000/svg'
  const svg = document.createElementNS(svgNamespace, 'svg')
  svg.setAttribute('width', (rowWidth * normalisedRowsLength).toString())
  svg.setAttribute('height', rowHeight.toString())
  svg.setAttribute('fill', '#E5E5E5')

  normalisedRows.forEach((filled, i) => {
    const rect = document.createElementNS(svgNamespace, 'rect')
    rect.setAttribute('x', (i * rowWidth).toString())
    rect.setAttribute('y', '0')
    rect.setAttribute('width', rowWidth.toString())
    rect.setAttribute('height', rowHeight.toString())
    if (filled) {
      rect.setAttribute('style', 'fill: #64748B')
    }
    svg.appendChild(rect)
  })

  return svg.outerHTML
}

export const tooltipFormatter = (
  params: TopLevelFormatterParams,
  t: TFunction,
  properties: {
    key: string
    value: string
    color: string
  }[]
) => {
  // @ts-expect-error - TS doesn't know that params is an object
  const { name, value, data } = params[0]
  const { rowStart, rowEnd, rowsScanned } = data

  const dateString = formatISO_(name.split('>')[1], 'dd MMM yyyy')
  const valueWithoutDateString = name.split('>')[0] || name

  let result = `<div style="margin-bottom: 6px; width: 200px">
                    <div style="text-align: left; font-weight: 600; font-size: 14px; line-height: 1.2">${valueWithoutDateString}</div>
                    <div style="text-align: left; font-size: 14px; line-height: 1.2">${dateString}</div>
                </div>`

  const barcodeMarkup = generateBarcodeSVG(rowStart, rowEnd, rowsScanned)
  result += `<div style="margin-bottom: 12px;">${barcodeMarkup}</div>`

  properties.forEach((item) => {
    if (value[item.value] !== 0) {
      result += `<div style="display: flex; color: #37474F; justify-content: space-between; gap: 2; background-color: ${item.color}">
                    <b style="width: 60px; padding:6px 0 6px 8px;">${t(item.key)}:</b>
                    <b style="padding:6px 8px 6px 8px">${value[item.value]}</b>
                </div>`
    }
  })

  const { 'Unknown Vines': unknownVinesCount, 'Low Quality Vines': lowQualityVinesCount } = value

  if (unknownVinesCount > 0 || lowQualityVinesCount > 0) {
    result += `<div style="margin-top: 12px; border-top: 1px solid lightgrey; margin-bottom: 6px; width: 200px">
                    <div style="text-align: left; font-size: 14px; padding-top: 8px;">${t('excluded')}</div>
                </div> `
  }

  if (unknownVinesCount > 0)
    result += `<div style="display: flex; color: #64748B; justify-content: space-between; gap: 2; background-color: #F3F4F6">
                    <b style="width: 60px; padding:6px 0 6px 8px">${t('unknown')}:</b>
                    <b style="padding:6px 8px 6px 8px">${unknownVinesCount}</b>
                </div>`

  if (lowQualityVinesCount > 0)
    result += `<div style="display: flex; color: #64748B; justify-content: space-between; gap: 2; background-color: #F3F4F6">
                   <b style="width: 60px; padding:6px 0 6px 8px">${t('low_quality')}:</b>
                   <b style="padding:6px 8px 6px 8px">${lowQualityVinesCount}</b>
                </div>`

  return result
}

export const boxplotTooltipFormatter = (params: TopLevelFormatterParams, t: TFunction) => {
  // @ts-expect-error - TS doesn't know that params is an object
  const name = params[0].name
  const dateString = formatISO_(name.split('>')[1], 'dd MMM yyyy')
  const valueWithoutDateString = name.split('>')[0] || name

  // @ts-expect-error - TS doesn't know that params is an object
  const [, , lq, mean, uq] = params[0].value
  return `<b>${valueWithoutDateString}</b><br />${dateString}<br/><div style="border-bottom: 1px solid grey; margin: 6px 0px"></div>${t('lower_quartile')}: ${formatToSingleDecimalIfNecessary(lq)}<br />${t('mean')}: ${formatToSingleDecimalIfNecessary(mean)}<br />${t('upper_quartile')}: ${formatToSingleDecimalIfNecessary(uq)}`
}

export const labelFormatter_Land = (value: string) => {
  // date string next to ">" character in string
  const dateString = formatISO_(value.split('>')[1])
  const valueWithoutDateString = value.split('>')[0] || value
  return valueWithoutDateString.length > 17
    ? valueWithoutDateString.slice(0, 16) + `..\n${dateString} + H`
    : valueWithoutDateString + `\n${dateString}`
}

export const labelFormatter_PruningCaneTarget = (value: string) => {
  const valueWithoutDateAndLastChar = value.split('>')[0] || value
  const dateString = formatISO_(value.split('>')[1])
  const lastChar = value.split('>')[2]

  return valueWithoutDateAndLastChar.length > 14
    ? valueWithoutDateAndLastChar.slice(0, 14) + `.. [${lastChar}] \n${dateString}`
    : valueWithoutDateAndLastChar + ` [${lastChar}] \n${dateString}`
}

export const LandSpur_TooltipProperties = [
  { key: 'missing_vines', value: 'missingVines', color: '#F87171' },
  { key: '2_missing_cordons', value: 'missingCordon1', color: '#FB923C' },
  { key: '1_missing_cordon', value: 'missingCordon2', color: '#FDE047' },
  { key: 'young_vines', value: 'youngVines', color: '#60A5FA' },
]

export const LandBow_TooltipProperties = [
  { key: 'missing_vines', value: 'missingVines', color: '#F87171' },
  { key: 'young_vines', value: 'youngVines', color: '#60A5FA' },
]

export const LandCane_TooltipProperties = [
  { key: 'missing', value: 'missingVines', color: '#F87171' },
  { key: 'dead', value: 'deadVines', color: '#94A3B8' },
  { key: 'young', value: 'youngVines', color: '#60A5FA' },
]
