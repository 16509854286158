import SingleToggleBtn from '@components/form/SingleToggleBtn'
import { DiseaseFilterOption } from '@typings/component.enums'

type HealthDatagridColumnsToggleProps = {
  selection: DiseaseFilterOption
  onClick: (option: DiseaseFilterOption) => void
}

const HealthDatagridColumnsToggle = ({ selection, onClick }: HealthDatagridColumnsToggleProps) => {
  const width = 170

  return (
    <div style={{ display: 'flex', gap: 8, padding: '8px 0', width: 'fit-content' }}>
      <SingleToggleBtn
        label="mildew"
        isSelected={selection === DiseaseFilterOption.PowderMildew}
        onClick={() => onClick(DiseaseFilterOption.PowderMildew)}
        width={width}
      />
      <SingleToggleBtn
        label="botrytis"
        isSelected={selection === DiseaseFilterOption.Botrytis}
        onClick={() => onClick(DiseaseFilterOption.Botrytis)}
        width={width}
      />
      <SingleToggleBtn
        label="damaged_bunch"
        isSelected={selection === DiseaseFilterOption.DamagedBunch}
        onClick={() => onClick(DiseaseFilterOption.DamagedBunch)}
        width={width}
      />
      <SingleToggleBtn
        label="flavescence_doree"
        isSelected={selection === DiseaseFilterOption.FlavescenceDoree}
        onClick={() => onClick(DiseaseFilterOption.FlavescenceDoree)}
        width={width}
      />
    </div>
  )
}

export default HealthDatagridColumnsToggle
