import { GridColDef, GridValidRowModel, GridValueFormatter } from '@mui/x-data-grid-pro'
import { CountryCode } from '@typings/common'
import { Bracket, CustomSliderMark, ScannedVineyardAndBlocks } from '@typings/component'
import { BillingStatus } from '@typings/dtos/billing/billing.enum'
import { BlockDto, BlockScanDateDto } from '@typings/dtos/block'
import { BlockData, BlockReport } from '@typings/dtos/block-report/models'
import { YieldBlockReport, YieldScan } from '@typings/dtos/block/yield-report'
import { VineyardDto } from '@typings/dtos/vineyard'
import { format, formatISO } from 'date-fns'
import { TFunction } from 'i18next'
import { countriesInNorthHemisphere } from './constants'
import { seasonsByHemisphere } from './options'

export function localStorageGetItem(key: string, defaultValue = '') {
  return localStorage.getItem(key) || defaultValue
}

export async function copyToClipboard(value: unknown) {
  const stringValue = String(value)

  try {
    await navigator.clipboard.writeText(stringValue)
  } catch (error) {
    console.error('failed to copy to clipboard', error)
  }
}

export function openGoogleMapsLink(longitude: number, latitude: number) {
  const url = `https://www.google.com/maps/search/?api=1&query=${longitude},${latitude}`
  return window.open(url, '_blank', 'noopener,noreferrer')
}

export function formatISOWithCustomHours(date: Date, { h = 0, m = 0, s = 0, ms = 0 } = {}) {
  const newDate = new Date(date)
  newDate.setHours(h, m, s, ms)
  return formatISO(newDate)
}

export function getBlocksFromScanDates(blocks: BlockDto[], startDate: Date, endDate: Date) {
  return blocks.filter((block) => {
    const blockDate = new Date(block.scanDate)
    return blockDate >= startDate && blockDate <= endDate
  })
}

export function rowStatsPruningToEChartsData(data: BlockReport[], t: TFunction) {
  let totalVinesWithTargetedCane = 0
  let totalVinesWithCanes = 0

  data.forEach((report) => {
    const b = report.blockData || {}
    const { caneTarget } = b

    if (caneTarget !== undefined) {
      const blockKeyByCaneTarget = caneTarget === 1 ? 'cane1' : caneTarget === 2 ? 'cane2' : caneTarget === 3 ? 'cane3' : 'cane4'

      totalVinesWithTargetedCane += (b[blockKeyByCaneTarget] as number) || 0
    }

    totalVinesWithCanes += (b.cane1 || 0) + (b.cane2 || 0) + (b.cane3 || 0) + (b.cane4 || 0)
  })

  const totalPrunedPercentage = totalVinesWithCanes
    ? Number(((totalVinesWithTargetedCane / totalVinesWithCanes) * 100).toFixed(1))
    : 0

  return [
    { name: t('pruned_to_target'), value: totalPrunedPercentage, vines: totalVinesWithTargetedCane },
    {
      name: t('not_pruned_to_target'),
      value: Number((100 - totalPrunedPercentage).toFixed(1)),
      vines: totalVinesWithCanes - totalVinesWithTargetedCane,
    },
  ]
}

export function createCanesDataset(data: BlockReport[], calculatedBy: 'cane' | 'vine') {
  if (calculatedBy === 'vine') {
    return {
      id: 'canes-dataset',
      dimensions: ['blockName', 'itemA', 'itemB', 'itemC', 'itemD'],
      source: data.map((item) => {
        const b = item.blockData || {}
        const caneTarget = b.caneTarget || 0

        let itemA = 0
        let itemB = 0
        let itemC = 0
        let itemD = 0

        if (caneTarget === 4) {
          itemA = b.cane4Pct || 0
          itemB = b.cane3Pct || 0
          itemC = b.cane2Pct || 0
          itemD = b.cane1Pct || 0
        } else if (caneTarget === 3) {
          itemA = b.cane3Pct || 0
          itemB = 0
          itemC = b.cane2Pct || 0
          itemD = b.cane1Pct || 0
        } else if (caneTarget === 2) {
          itemA = b.cane2Pct || 0
          itemB = 0
          itemC = 0
          itemD = b.cane1Pct || 0
        } else if (caneTarget === 1) {
          itemA = b.cane1Pct || 0
          itemB = 0
          itemC = 0
          itemD = 0
        }

        return {
          blockName: item.blockName + '>' + item.dateEnd + '>' + caneTarget,
          caneTarget,
          rowStart: item.rowStart,
          rowEnd: item.rowEnd,
          rowsScanned: b.rowsScanned.map((row) => row.rowNum),
          itemA: formatToSingleDecimalIfNecessary(itemA),
          itemB: formatToSingleDecimalIfNecessary(itemB),
          itemC: formatToSingleDecimalIfNecessary(itemC),
          itemD: formatToSingleDecimalIfNecessary(itemD),
          cane4: fmtNumToThousandSepSingleDecOrZero(b.cane4 || 0),
          cane3: fmtNumToThousandSepSingleDecOrZero(b.cane3 || 0),
          cane2: fmtNumToThousandSepSingleDecOrZero(b.cane2 || 0),
          cane1: fmtNumToThousandSepSingleDecOrZero(b.cane1 || 0),
          cane4Pct: formatToSingleDecimalIfNecessary(b.cane4Pct),
          cane3Pct: formatToSingleDecimalIfNecessary(b.cane3Pct),
          cane2Pct: formatToSingleDecimalIfNecessary(b.cane2Pct),
          cane1Pct: formatToSingleDecimalIfNecessary(b.cane1Pct),
        }
      }),
    }
  }

  return {
    id: 'canes-dataset',
    dimensions: ['blockName', 'canesLaidPct', 'canesNotLaidPct'],
    source: data.map((item) => {
      const b = item.blockData || {}
      const caneTarget = b.caneTarget || 0

      return {
        blockName: item.blockName + '>' + item.dateEnd + '>' + caneTarget,
        caneTarget,
        rowStart: item.rowStart,
        rowEnd: item.rowEnd,
        rowsScanned: b.rowsScanned.map((row) => row.rowNum),
        canesLaid: b.canesLaid,
        canesNotLaid: b.canesNotLaid,
        canesLaidPct: fmtNumToThousandSepSingleDecOrZero(b.canesLaidPct),
        canesNotLaidPct: fmtNumToThousandSepSingleDecOrZero(100 - b.canesLaidPct),
      }
    }),
  }
}

export function colorByAssumedCaneTarget(assumedCaneTarget: number, key: keyof BlockData) {
  if (assumedCaneTarget === 4) {
    return key === 'cane4' ? '#34D399' : key === 'cane3' ? '#60A5FA' : key === 'cane2' ? '#FACC15' : '#F87171'
  } else if (assumedCaneTarget === 3) {
    return key === 'cane3' ? '#34D399' : key === 'cane2' ? '#FACC15' : key === 'cane1' ? '#F87171' : '#E7E5E4'
  } else if (assumedCaneTarget === 2) {
    return key === 'cane2' ? '#34D399' : key === 'cane1' ? '#F87171' : '#E7E5E4'
  } else if (assumedCaneTarget === 1) {
    return key === 'cane1' ? '#34D399' : '#E7E5E4'
  } else return '#E7E5E4'
}

export function colorByVineStatsKey(key: string) {
  return key === 'young_vines' ? '#60A5FA' : key === 'dead_vines' ? '#94A3B8' : '#F87171'
}

export function createLandVineStatsDataset(data: BlockReport[]) {
  const dataset = data.map((item) => {
    const { blockData: b } = item
    return {
      id: item.id,
      dateEnd: item.dateEnd,
      blockName: item.blockName + '>' + item.dateEnd,
      missingVines: b.vMissing,
      deadVines: b.vDead,
      youngVines: b.vYoung,
      missingCordon1: b.missingCordon1 || 0,
      missingCordon2: b.missingCordon2 || 0,
      wooden: b.pWooden || 0,
      metal: b.pMetal || 0,
      missing: b.pMissing || 0,
      unknown: b.pUnknown || 0,
      rowsScanned: b.rowsScanned.map((row) => row.rowNum),
      rowStart: item.rowStart,
      rowEnd: item.rowEnd,
    }
  })

  return dataset
}

export const numberOfUniqueBlocks = (data: { blockId: number }[]) => {
  const blocks = new Set<number>()
  for (const row of data) blocks.add(row.blockId)
  return blocks.size
}

export const uniqueBlockNames = (data: { blockName: string }[]) => {
  const uniqueBlockNames = new Set()
  return data.filter((block) => {
    if (uniqueBlockNames.has(block.blockName)) return false
    else {
      uniqueBlockNames.add(block.blockName)
      return true
    }
  })
}

//number separator en-NZ
export const numberFmtThousandSeparator = (value: number) => {
  if (typeof value !== 'number') return value
  return value.toLocaleString('en-NZ')
}

export function formatDateRange(startDate: string, endDate: string): string {
  const parsedStartDate = new Date(startDate)
  const parsedEndDate = new Date(endDate)

  const formattedStartDate = format(parsedStartDate, 'd MMM yyyy')
  const formattedEndDate = format(parsedEndDate, 'd MMM yyyy')

  return `${formattedStartDate} - ${formattedEndDate}`
}

export const findNearestDateMark = (marks: CustomSliderMark[], targetDate: number): CustomSliderMark | null => {
  let nearestMark: CustomSliderMark | null = null
  let minDifference = Infinity

  for (const mark of marks) {
    const difference = Math.abs(targetDate - mark.value)
    if (difference < minDifference) {
      minDifference = difference
      nearestMark = mark
    }
  }

  return nearestMark
}

export const formatISO_ = (date?: string | number | Date, formatStr?: string) => {
  if (!date) return ''
  return format(date, formatStr || 'dd MMM')
}

export const calculateChange = (current: number | null, previous: number | null): 'up' | 'down' | 'none' => {
  if (current === null || previous === null) return 'none'

  if (current > previous) return 'up'
  else if (current < previous) return 'down'
  else return 'none'
}

const sortScansByDate = (scans: YieldScan[]): YieldScan[] => {
  return [...scans].sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
}

export const modifyBlockYieldReports = (reports: YieldBlockReport[]): YieldBlockReport[] => {
  return reports.map((report) => {
    const sortedScans = sortScansByDate(report.scans)
    const modifiedScans = sortedScans.map((scan, index) => {
      const previousScan = index > 0 ? sortedScans[index - 1] : null
      const visibleChange = previousScan ? calculateChange(scan.visible, previousScan.visible) : 'none'
      const correctedChange = previousScan ? calculateChange(scan.corrected, previousScan.corrected) : 'none'
      return { ...scan, visibleChange, correctedChange }
    })
    return { ...report, scans: modifiedScans }
  })
}

export const datagridColumnValueFormatter: GridValueFormatter = (value) => {
  if (value === undefined || value === null) return '—'
  return value
}

// Curried formatter function
export const createFormatter = (customFormatter?: (value: any) => any) => {
  return (value: never, row: GridValidRowModel, column: GridColDef<GridValidRowModel, any, any>, apiRef: any): any => {
    const formattedValue = datagridColumnValueFormatter(value, row, column, apiRef)
    if (formattedValue === '—') return formattedValue // Return early if value is '—'
    return customFormatter ? customFormatter(formattedValue) : formattedValue
  }
}

export const formatToSingleDecimalIfNecessary = (value: number | null | undefined): number => {
  if (value === 0 || value === null || value === undefined) return 0

  if (Number.isInteger(value)) return value
  else return parseFloat(value.toFixed(1))
}

export const fmtNumToThousandSepSingleDecOrZero = (value: number | null | undefined) => {
  if (value === null || value === undefined) return 0

  return Intl.NumberFormat('en-NZ', { maximumFractionDigits: 1 }).format(Number(value) ?? 0)
}

export const fmtNumToThousandSepSingleDecOrDash = (value: number | null | undefined) => {
  if (value === null || value === undefined || typeof value != 'number') return '—'
  return Intl.NumberFormat('en-NZ', { maximumFractionDigits: 1 }).format(Number(value))
}

export const formatToSingleDecimalIfNecessaryOrDash = (value: number | null | undefined) => {
  if (value === null || value === undefined) return '—'

  if (Number.isInteger(value)) return value
  else return Number(value.toFixed(1))
}

export const findOldestAndNewestDates = (reports: { reports: { dateEnd: string }[] }[] | undefined) => {
  if (!reports) return { oldestDate: '', newestDate: '' }
  let oldestDate: string = ''
  let newestDate: string = ''

  // Iterate through each DiseaseBlockReport
  reports.forEach(({ reports }) => {
    // Iterate through scans in each block
    reports.forEach((scan) => {
      // Update oldest date if it's empty or current scan date is older
      if (!oldestDate || scan.dateEnd < oldestDate) {
        oldestDate = scan.dateEnd
      }

      // Update newest date if it's empty or current scan date is newer
      if (!newestDate || scan.dateEnd > newestDate) {
        newestDate = scan.dateEnd
      }
    })
  })

  return { oldestDate, newestDate }
}

export const colorByStatus: { [key: number]: string } = {
  [BillingStatus.Active]: '#3B82F6',
  [BillingStatus.Settled]: '#84CC16',
  [BillingStatus.Overdue]: '#EF4444',
}

export function calculateCostByBrackets(brackets: Bracket[], v: number | null): number {
  let totalCost = 0
  if (v === null || v === undefined) return totalCost

  let remainingScans = v

  for (let i = 0; i < brackets.length && remainingScans > 0; i++) {
    const bracket = brackets[i]
    if (bracket) {
      // Determine the actual rangeEnd for the current bracket
      const rangeEnd = bracket.rangeEnd === -1 ? remainingScans : bracket.rangeEnd
      const applicableScans = Math.min(remainingScans, rangeEnd - bracket.rangeStart + 1)
      totalCost += applicableScans * bracket.amount
      remainingScans -= applicableScans
    }
  }

  return totalCost
}

export function transformBrackets(brackets: Record<string, number>) {
  let data: Bracket[] = []
  const keys = Object.keys(brackets).map(Number)
  const maxRange = Math.max(...keys)

  const colors = ['#FB923C', '#FACC15', '#4ADE80', '#60A5FA', '#A78BFA']

  // Create ranges based on keys
  for (let i = 0; i < keys.length; i++) {
    const rangeStart = i === 0 ? 1 : (keys[i - 1] || 0) + 1
    const rangeEnd = Number(keys[i])
    const amount = brackets[String(keys[i])] || 0
    const color = colors[i] || '#000'

    // Calculate segment width
    let rangeWidth = 0
    if (rangeEnd && rangeEnd !== -1) {
      rangeWidth = ((rangeEnd - (rangeStart - 1)) / maxRange) * 100
    }

    // Push the transformed object into data array
    data.push({ rangeStart, rangeEnd, amount, color, rangeWidth })
  }

  return data
}

export const findBillingStatus = (dueDate: string | undefined, paidDate: string | undefined): BillingStatus => {
  if (paidDate) return BillingStatus.Settled
  if (dueDate && new Date(dueDate) < new Date()) return BillingStatus.Overdue
  return BillingStatus.Active
}

export function createPostsDataset(data: BlockReport[]) {
  let source: { blockName: string; wooden: number; metal: number; missing: number; unknown: number }[] = []

  data.forEach((item) => {
    const { blockData: b } = item
    source.push({
      blockName: item.blockName,
      wooden: b.pWooden,
      metal: b.pMetal,
      missing: b.pMissing,
      unknown: b.pUnknown,
    })
  })

  const dataset = {
    id: 'posts-stats-dataset',
    dimensions: ['blockName', 'wooden', 'metal', 'missing', 'unknown'],
    source,
  }

  return dataset
}

export function adjustPercentagesTo100(values: number[], modifier: (value: number) => number = (value) => value) {
  let percentages

  if (modifier) percentages = values.map(modifier)
  else percentages = values

  const total = percentages.reduce((acc, curr) => acc + curr, 0)
  // If the sum is already 100, the original values is returned.
  if (total === 100) return percentages

  const diff = 100 - total
  // Error if the difference between the total and 100 is greater than 0.9.
  if (Math.abs(diff) > 0.9)
    throw new Error('The difference between the total and 100 is greater than 0.9. Please check the data.')

  // Find the highest value in the array.
  const highestValue = Math.max(...percentages)

  // Find the number of decimal values in the highest value
  const numberOfDecimalValuesInMax = highestValue.toString().split('.')[1]?.length || 1

  // Find the index of the highest value in the array.
  const index = percentages.indexOf(highestValue)

  // Adjust the highest value by adding or subtracting the difference
  percentages[index] = Number((highestValue + diff).toFixed(numberOfDecimalValuesInMax))

  return percentages
}

export const getLandingPage = (country: CountryCode | undefined): string => {
  if (!country) return 'health'
  const today = new Date()
  const month = today.getMonth() + 1
  const hemisphere = countriesInNorthHemisphere.includes(country) ? 'north' : 'south'
  const season = seasonsByHemisphere[hemisphere].find((s) => month >= s.startMonth && month <= s.endMonth)
  if (!season) return 'health'
  return season.landingPage
}

export function groupScannedBlocksAndVineyards(
  blockScanData: BlockScanDateDto[],
  vineyardsAsMap: Map<number, VineyardDto>,
  blocksAsMap: Map<number, BlockDto>
): ScannedVineyardAndBlocks[] {
  const scannedVineyardsMap = new Map<string, Map<number, Set<number>>>()

  const len = blockScanData.length
  for (let i = 0; i < len; i++) {
    const { blockId, vineyardId, scanDate } = blockScanData[i]!

    const date = format(scanDate, 'dd MMM yyyy')

    // Get or create the vineyard map for the scanDate
    if (!scannedVineyardsMap.has(date)) {
      scannedVineyardsMap.set(date, new Map())
    }

    const vineyardMap = scannedVineyardsMap.get(date)!

    // Get or create the set of blocks for the vineyard
    if (!vineyardMap.has(vineyardId)) {
      vineyardMap.set(vineyardId, new Set())
    }

    const blocksSet = vineyardMap.get(vineyardId)!

    // Add the blockId to the blocks set (automatically handles duplicates)
    blocksSet.add(blockId)
  }

  // Convert the scannedVineyardsMap back to the desired output format
  const scannedVineyards: ScannedVineyardAndBlocks[] = []

  scannedVineyardsMap.forEach((vineyardMap, scanDate) => {
    const vineyards: ScannedVineyardAndBlocks['vineyards'] = []

    vineyardMap.forEach((blocksSet, vineyardId) => {
      const blocks = Array.from(blocksSet).map((id) => ({ id, name: blocksAsMap.get(id)?.name || 'Unknown Block' }))
      vineyards.push({ id: vineyardId, name: vineyardsAsMap.get(vineyardId)?.name || 'Unknown Vineyard', blocks })
    })

    scannedVineyards.push({ scanDate, vineyards })
  })

  // Sort the scannedVineyards by scanDate
  scannedVineyards.sort((a, b) => new Date(b.scanDate).getTime() - new Date(a.scanDate).getTime())

  return scannedVineyards
}
