import Paper from '@mui/material/Paper'
import Slider, { SliderProps } from '@mui/material/Slider'
import { CustomSliderMark } from '@typings/component'
import { addDays, differenceInDays, format, parseISO } from 'date-fns'
import { useEffect, useRef, useState } from 'react'

type DateRangeSliderProps = {
  startDate: string
  endDate: string
  targetDate: number
  customOnChange: (item: CustomSliderMark) => void
} & SliderProps

const DateRangeSlider = ({ startDate, endDate, targetDate, customOnChange, ...props }: DateRangeSliderProps) => {
  const [marks, setMarks] = useState<CustomSliderMark[]>([])
  const sliderRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const newStartDate = parseISO(startDate)
    const newEndDate = parseISO(endDate)

    if (sliderRef.current) {
      const markLabels = sliderRef.current.querySelectorAll('.MuiSlider-markLabel')
      const marks = sliderRef.current.querySelectorAll('.MuiSlider-mark')
      marks.forEach((mark) => ((mark as HTMLElement).style.backgroundColor = 'white')) //set all to white
      markLabels.forEach((markLabel) => ((markLabel as HTMLElement).style.display = 'none')) //set all to display none
    }

    // Calculate the new total number of days between start and end dates
    const newTotalDays = Math.floor(differenceInDays(newEndDate, newStartDate))

    // Generate marks array for each day
    const newMarks: CustomSliderMark[] = Array.from({ length: newTotalDays + 1 }, (_, i) => {
      const currentDate = addDays(newStartDate, i)
      return {
        value: i,
        label: format(currentDate, 'dd MMM'),
        date: new Date(currentDate).getTime().toString(),
        clusterId: '',
      }
    })

    // Update the marks state
    setMarks(newMarks)
  }, [startDate, endDate])

  useEffect(() => {
    // Apply CSS properties after component has re-rendered and DOM has updated
    const updateSliderStyles = () => {
      setTimeout(() => {
        if (sliderRef.current) {
          const markLabels = sliderRef.current.querySelectorAll('.MuiSlider-markLabel')
          const marks = sliderRef.current.querySelectorAll('.MuiSlider-mark')

          const totalMarks = Math.min(marks.length, 10) // Maximum 10 marks to display
          const step = Math.max(1, Math.floor((marks.length - 1) / (totalMarks - 1)))

          for (let i = 0; i < totalMarks; i++) {
            const index = i === totalMarks - 1 ? marks.length - 1 : i * step
            const markLabel = markLabels[index] as HTMLElement
            const mark = marks[index] as HTMLElement
            markLabel.style.display = 'block'
            mark.style.backgroundColor = '#A8A29E'
          }
        }
      }, 1000) // Delay of 1 second to allow DOM to update
    }

    updateSliderStyles()
  })

  return (
    <Paper
      sx={{
        position: 'absolute',
        bottom: 8,
        right: 0,
        left: 0,
        height: 100,
        width: '99%',
        mx: 'auto',
        px: 6,
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Slider
        {...props}
        ref={sliderRef}
        aria-label="Date range slider"
        defaultValue={0}
        track={false}
        step={1}
        valueLabelDisplay="auto"
        marks={marks}
        min={0}
        max={marks.length - 1}
        valueLabelFormat={(val) => {
          return marks.find((mark) => mark.value === val)?.label
        }}
        value={marks.findIndex((mark) => mark.date === targetDate.toString())}
        onChange={(_, newValue) => {
          const item = marks.find((mark) => mark.value === newValue)
          if (item) customOnChange(item)
        }}
        sx={{
          height: 8,
          borderRadius: 0,
          color: '#78716C',
          '.MuiSlider-mark': { height: 14, bgcolor: 'white' },
          '.MuiSlider-thumb': { borderRadius: 0 },
          '.MuiSlider-markLabel': { display: 'none', fontSize: '0.9rem', fontWeight: 500 },
          position: 'relative',
        }}
      ></Slider>
    </Paper>
  )
}

export default DateRangeSlider
