import CustomTableWrapper from '@components/containers/CustomTableWrapper'
import BarcodedRows from '@components/visualisations/BarcodedRows'
import { DiseaseReportsByBlock } from '@hooks/health/useGetDiseaseBlockReports'
import { BlockReport, DiseaseStats } from '@typings/dtos/block-report/models'
import { formatISO_ } from '@utils/utility-fns'
import { useMemo } from 'react'

type HealthTableProps = {
  block: DiseaseReportsByBlock
  detectionsToShow: Array<keyof DiseaseStats>
}

const HealthTable = ({ block, detectionsToShow }: HealthTableProps) => {
  const bubble = useMemo(
    () => (detections: number) => {
      if (detectionsToShow.length === 0) return { size: 0, color: 'transparent' }

      if (detections >= 201) return { size: 55, color: '#FCA5A5' }
      else if (detections >= 51) return { size: 45, color: '#FDE047' }
      else if (detections >= 1) return { size: 35, color: '#86EFAC' }
      else return { size: 0, color: 'transparent' }
    },
    [detectionsToShow]
  )

  const pctOfRowsScanned = (report: BlockReport) => {
    const { rowStart, rowEnd } = block
    if (rowStart === 0 || rowEnd === 0) return 0

    const totalRows = rowEnd - rowStart + 1
    const rowsScanned = report.blockData.rowsScanned.length
    return Math.round((rowsScanned / totalRows) * 100)
  }

  return (
    <CustomTableWrapper blockName={block.blockName} vineyardName={block.vineyardName}>
      {block.reports.map((report) => {
        const { id, dateStart, dateEnd, blockData } = report
        const { pmTotalInfectedVines, bbrInfectedVines } = blockData

        const sumV = detectionsToShow.reduce((acc, key) => {
          if (blockData[key]) return acc + blockData[key]
          return acc
        }, 0)

        const pct = pctOfRowsScanned(report)

        return (
          <div
            style={{ cursor: 'pointer', backgroundColor: '#FAFAFA', borderRadius: 4, border: '2px solid #F5F5F5', height: 140 }}
            className="custom-table-col"
            key={id}
          >
            <div className="custom-table-head" style={{ padding: '8px 12px 12px 12px', height: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', paddingBottom: 2 }}>
                <div style={{ fontSize: 13, fontWeight: 500, color: '#525252' }}>
                  {formatISO_(dateStart)} &#8212; {formatISO_(dateEnd)}
                </div>
                {pct > 0 && <div style={{ fontSize: 12, color: '#737373' }}>{pct}%</div>}
              </div>
              <BarcodedRows
                color="#737373"
                rowsStartNumber={block.rowStart}
                rowsEndNumber={block.rowEnd}
                rowsScanned={blockData.rowsScanned.map((row) => row.rowNum)}
                style={{ borderRadius: 3, overflow: 'hidden' }}
              />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <table style={{ fontSize: 12, height: 'fit-content' }}>
                  <tbody>
                    {pmTotalInfectedVines ? (
                      <tr>
                        <td style={{ color: '#57534E' }}>MW</td>
                        <td>{pmTotalInfectedVines}</td>
                      </tr>
                    ) : null}
                    {bbrInfectedVines > 0 ? (
                      <tr>
                        <td style={{ color: '#57534E' }}>BT</td>
                        <td>{bbrInfectedVines}</td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
                {sumV > 0 && (
                  <div
                    style={{
                      height: bubble(sumV).size,
                      backgroundColor: bubble(sumV).color,
                      width: bubble(sumV).size,
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 13,
                      fontWeight: 500,
                      margin: '6px 0px 0px 0px',
                      boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    {sumV}
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </CustomTableWrapper>
  )
}

export default HealthTable
