import SummaryFiltersWrapper from '@components/containers/SummaryFiltersWrapper'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import { SUMMARY_LABEL_CHIP_STYLES } from '@services/theme/styles'
import { DiseaseStats } from '@typings/dtos/block-report/models'
import { DiseaseOptions, HealthBlockReport_SortOptions } from '@utils/options'
import { t } from 'i18next'

type HealthFiltersSectionProps = {
  showDiseases: Array<keyof DiseaseStats>
  setShowDiseases: (diseases: Array<keyof DiseaseStats>) => void
  sort: string
  setSort: (sort: string) => void
}

const HealthFiltersSection = ({ showDiseases, setShowDiseases, sort, setSort }: HealthFiltersSectionProps) => {
  const selectedOptions = (selected: (keyof DiseaseStats)[]) =>
    DiseaseOptions.filter((option) => selected.includes(option.value))
      .map((option) => t(option.label))
      .join(', ')

  return (
    <SummaryFiltersWrapper
      label={t('detections')}
      legends={
        <>
          <div style={{ ...SUMMARY_LABEL_CHIP_STYLES, backgroundColor: '#86EFAC', width: '5rem' }}>1&mdash;50</div>
          <div style={{ ...SUMMARY_LABEL_CHIP_STYLES, backgroundColor: '#FDE047', width: '5rem' }}>51&mdash;200</div>
          <div style={{ ...SUMMARY_LABEL_CHIP_STYLES, backgroundColor: '#FCA5A5', width: '5rem' }}>200+</div>
        </>
      }
      rightSection={
        <>
          <FormControl>
            <InputLabel id="select-diseases-label" shrink={true} sx={{ fontWeight: 500, textTransform: 'uppercase' }}>
              {t('diseases')}
            </InputLabel>
            <Select
              labelId="select-diseases-label"
              id="select-diseases"
              multiple
              value={showDiseases}
              onChange={(e) => setShowDiseases(e.target.value as Array<keyof DiseaseStats>)}
              renderValue={(selected) => selectedOptions(selected as (keyof DiseaseStats)[])}
              input={<OutlinedInput label="DISEASESi" sx={{ fontWeight: 500, textTransform: 'uppercase' }} />}
              sx={{ width: 230, height: 40, bgcolor: '#fff', color: '#334155', fontSize: 15 }}
            >
              {DiseaseOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={showDiseases.indexOf(option.value) > -1} />
                  <ListItemText primary={t(option.label)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="select-diseases-sort-label" shrink={true} sx={{ fontWeight: 500, textTransform: 'uppercase' }}>
              {t('sort')}
            </InputLabel>
            <Select
              labelId="select-diseases-sort-label"
              id="select-diseases-sort"
              value={sort}
              onChange={(e) => setSort(e.target.value)}
              input={<OutlinedInput label="SORT" sx={{ fontWeight: 500, textTransform: 'uppercase' }} />}
              sx={{ width: 230, height: 40, bgcolor: '#fff', color: '#334155', fontSize: 15 }}
            >
              {HealthBlockReport_SortOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {t(option.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      }
    />
  )
}

export default HealthFiltersSection
