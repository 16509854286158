import { IOption, LandingPageByCountryAndDate } from '@typings/common'
import { ToggleCompareBlockEnum } from '@typings/component.enums'
import { BlockPruningStyleEnum } from '@typings/dtos/block'
import { DiseaseStats } from '@typings/dtos/block-report/models'

export const toggleCompareBlockOptions: [IOption<string>, IOption<string>] = [
  { label: 'Pruning', value: ToggleCompareBlockEnum.Pruning },
  { label: 'Canes', value: ToggleCompareBlockEnum.Canes },
]

export const pruningSortOptions: IOption<string>[] = [
  { label: 'Block Name', value: 'name' },
  { label: 'Pruned to Target', value: 'pruned-to-target' },
]

export const diseaseSpreadSortOptions: IOption<string>[] = [{ label: 'block_name', value: 'blockName' }]

export const budBurstSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'blockName' },
  { label: 'budburst%', value: 'budBurstPct' },
]

export const canesSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'blockName' },
  { label: 'pruned_to_target', value: 'itemA' },
  { label: '4_cane', value: 'cane4Pct' },
  { label: '3_cane', value: 'cane3Pct' },
  { label: '2_cane', value: 'cane2Pct' },
  { label: '1_cane', value: 'cane1Pct' },
]

export const blockPotentialSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'blockName' },
  { label: 'canes_laid', value: 'caneLaidPct' },
]

export const LandCane_VineStatsSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'blockName' },
  { label: 'missing_vines', value: 'missingVines' },
  { label: 'dead_vines', value: 'deadVines' },
  { label: 'young_vines', value: 'youngVines' },
]

export const LandCane_PostsSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'Block Name' },
  { label: 'wooden_posts', value: 'wooden' },
  { label: 'metal_posts', value: 'metal' },
  { label: 'missing_posts', value: 'missing' },
  { label: 'unknown_posts', value: 'unknown' },
]

export const LandSpur_VineStatsSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'blockName' },
  { label: 'missing_vines', value: 'missingVines' },
  { label: 'young_vines', value: 'youngVines' },
  { label: '1_missing_cordon', value: 'missingCordon1' },
  { label: '2_missing_cordons', value: 'missingCordon2' },
]

export const LandBow_VineStatsSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'blockName' },
  { label: 'missing_vines', value: 'missingVines' },
  { label: 'young_vines', value: 'youngVines' },
]

export const expectedVineStatsSortMap = new Map([
  ['name-asc', 'name'],
  ['name-desc', 'name'],
  ['missing-vines-asc', 'Missing Vines%'],
  ['missing-vines-desc', 'Missing Vines%'],
  ['dead-vines-asc', 'Dead Vines%'],
  ['dead-vines-desc', 'Dead Vines%'],
  ['young-vines-asc', 'Young Vines%'],
  ['young-vines-desc', 'Young Vines%'],
])

export const labelByTrainingSystem: { [key in BlockPruningStyleEnum]: string } = {
  [BlockPruningStyleEnum.HighBow]: 'BOW',
  [BlockPruningStyleEnum.LowBow]: 'BOW',
  [BlockPruningStyleEnum.Spur]: 'SPUR',
  [BlockPruningStyleEnum.VSP]: 'CANE',
  [BlockPruningStyleEnum.Sylvos]: '',
  [BlockPruningStyleEnum.Unknown]: '',
  [BlockPruningStyleEnum.HighWire]: '',
}

export const budsPerVineSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'blockName' },
  { label: 'mean', value: 'budsPerVine' },
]

export const PruningCane_BudsPerVineSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'Block Name' },
  { label: 'lower_quartile', value: 'budsPerVineLQ' },
  { label: 'mean', value: 'budsPerVineMean' },
  { label: 'upper_quartile', value: 'budsPerVineUQ' },
]

export const PruningCane_BudsPerCaneSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'Block Name' },
  { label: 'lower_quartile', value: 'budsPerCaneLQ' },
  { label: 'mean', value: 'budsPerCaneMean' },
  { label: 'upper_quartile', value: 'budsPerCaneUQ' },
]

export const PruningSpur_BudsPerSpurPerVineSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'Block Name' },
  { label: 'lower_quartile', value: 'budsPerSpurPerVineLQ' },
  { label: 'mean', value: 'budsPerSpurPerVineMean' },
  { label: 'upper_quartile', value: 'budsPerSpurPerVineUQ' },
]

export const PruningSpur_SpursPerVineSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'Block Name' },
  { label: 'lower_quartile', value: 'spursPerVineLQ' },
  { label: 'mean', value: 'spursPerVineMean' },
  { label: 'upper_quartile', value: 'spursPerVineUQ' },
]

export const YieldBlockReport_SortOptions = [
  { label: 'most_visible', value: 'desc-visible' },
  { label: 'least_visible', value: 'asc-visible' },
  { label: 'most_corrected', value: 'desc-corrected' },
  { label: 'least_corrected', value: 'asc-corrected' },
  { label: 'vineyard_name_(desc)', value: 'desc-vineyard-name' },
  { label: 'vineyard_name_(asc)', value: 'asc-vineyard-name' },
]

export const DiseaseOptions: { label: string; value: keyof DiseaseStats }[] = [
  { label: 'mildew_(mw)', value: 'pmTotalInfectedVines' },
  { label: 'botrytis_(bt)', value: 'bbrInfectedVines' },
]

export const HealthBlockReport_SortOptions = [
  { label: 'high_detection_count', value: 'desc-high-detection-count' },
  { label: 'low_detection_count', value: 'asc-low-detection-count' },
  { label: 'vineyard_name_(desc)', value: 'desc-vineyard-name' },
  { label: 'vineyard_name_(asc)', value: 'asc-vineyard-name' },
]

export const seasonsByHemisphere: Record<'north' | 'south', LandingPageByCountryAndDate[]> = {
  north: [
    { season: 'in-season', landingPage: 'health', startMonth: 3, endMonth: 10 },
    { season: 'off-season', landingPage: 'pruning', startMonth: 11, endMonth: 2 },
  ],
  south: [
    { season: 'in-season', landingPage: 'health', startMonth: 10, endMonth: 4 },
    { season: 'off-season', landingPage: 'pruning', startMonth: 5, endMonth: 9 },
  ],
}
