export enum ToggleCompareBlockEnum {
  Pruning = 'pruning',
  Canes = 'canes',
}

export enum BlocksDataConditionEnum {
  NoBlock = 'NoBlock',
  OneBlock = 'OneBlock',
  BlocksButNoData = 'BlocksButNoData',
}

export enum DiseaseFilterOption {
  PowderMildew = 1,
  Botrytis = 2,
  DamagedBunch = 3,
  FlavescenceDoree = 4,
}
