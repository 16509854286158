import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const ScanRecencyLegend = () => {
  const { t } = useTranslate()
  return (
    <Box color="info.dark" px={1.5} py={1}>
      <Box display="flex" gap={1} fontWeight={500} alignItems="center" justifyContent="space-around" height={40}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography fontSize={14} fontWeight={500}>
            {t('days')}:
          </Typography>
        </Box>
        <Box
          height={30}
          width={40}
          bgcolor="#FBBF24"
          border="2px solid #FFF"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="white"
          boxShadow={1}
        >
          <Typography fontSize={14} fontWeight={500}>
            1-15
          </Typography>
        </Box>
        <Box
          height={30}
          width={40}
          bgcolor="#0C4A6E"
          border="2px solid #FFF"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="white"
          boxShadow={1}
        >
          <Typography fontSize={14} fontWeight={500}>
            15+
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ScanRecencyLegend
