import HighlightColumnWrapper from '@components/containers/HighlightColumnWrapper'
import HighlightsInfoWrapper from '@components/containers/HighlightsInfoWrapper'
import SummaryTileWrapper from '@components/containers/SummaryTileWrapper'
import HighlightInfo from '@components/data-display/HighlightInfo'
import SortBlocksMenu from '@components/echarts/SortBlocksMenu'
import BoxplotChart from '@components/echarts/charts/BoxplotChart'
import { useBlockDataHighlights } from '@hooks/useBlockDataHighlights'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { BlockReport } from '@typings/dtos/block-report/models'
import { labelFormatter_Land } from '@utils/echarts'
import { budsPerVineSortOptions } from '@utils/options'
import { formatISO_, formatToSingleDecimalIfNecessary, numberFmtThousandSeparator } from '@utils/utility-fns'
import { useEffect, useState } from 'react'
import ConditionalStatsTileContent from '../ConditionalStatsTileContent'

type BowPrunedSectionProps = {
  data: BlockReport[]
}

const BowPrunedSection = ({ data }: BowPrunedSectionProps) => {
  const [activeSort, setActiveSort] = useState('')
  const [bowPrunedData, setBowPrunedData] = useState(data)
  const { t } = useTranslate()

  useEffect(() => {
    if (activeSort === '') return

    const sortDataByBlockName = () => {
      setBowPrunedData((prev) => [
        ...prev.sort((a, b) =>
          activeSort === 'blockName-asc' ? b.blockName.localeCompare(a.blockName) : a.blockName.localeCompare(b.blockName)
        ),
      ])
    }
    if (activeSort.startsWith('blockName')) sortDataByBlockName()

    const sortDataByNumericalProperties = () => {
      setBowPrunedData((prev) => {
        //text before the first hyphen
        const sortKey = activeSort.split('-')[0] as keyof BlockReport['blockData']
        return [
          ...prev.sort((a, b) => {
            const valueA = a.blockData[sortKey] as number
            const valueB = b.blockData[sortKey] as number
            return activeSort.endsWith('-asc') ? valueB - valueA : valueA - valueB
          }),
        ]
      })
    }
    return sortDataByNumericalProperties()
  }, [activeSort])

  const { totalYoungVines, totalNormalVines, totalMissingVines, totalVines } = useBlockDataHighlights({ data })

  return (
    <>
      <Box display="flex" gap={2} flexWrap="wrap">
        <HighlightsInfoWrapper
          label="showing_stats_for"
          mainHighlightComponent={<ConditionalStatsTileContent data={data} />}
          tooltipInfo={'pruning_page.summary.tooltip_showing_stats_for'}
        />
        <HighlightsInfoWrapper
          label="vines_scanned"
          mainHighlightComponent={
            <HighlightInfo value={numberFmtThousandSeparator(totalVines)} label="total" color="info.dark" />
          }
        >
          <HighlightColumnWrapper>
            <HighlightInfo value={numberFmtThousandSeparator(totalYoungVines)} label="young_vines" />
            <HighlightInfo value={numberFmtThousandSeparator(totalMissingVines)} label="missing_vines" color="error.main" />
          </HighlightColumnWrapper>
          <HighlightColumnWrapper divider>
            <HighlightInfo value={numberFmtThousandSeparator(totalNormalVines)} label="normal_vines" />
          </HighlightColumnWrapper>
        </HighlightsInfoWrapper>
      </Box>
      <SummaryTileWrapper
        label="calculated_buds_per_vine"
        width="100%"
        height={460}
        tooltipMsg="pruning_page.summary.bow.tooltip_calc_buds_per_vine"
        headerComponent={
          <SortBlocksMenu
            id="sort-canes-data"
            menuItems={budsPerVineSortOptions}
            value={activeSort}
            onClick={(val) => setActiveSort(val)}
            menuArrowPositionRight={110}
          />
        }
      >
        {bowPrunedData.length && (
          <Box py={1}>
            <BoxplotChart
              data={bowPrunedData.map(({ blockData: b }) => {
                if (!b) return [0, 0, 0, 0, 0]
                return [b.budsPerVine, b.budsPerVine, b.budsPerVine, b.budsPerVine, b.budsPerVine]
              })}
              categoryData={bowPrunedData.map((item) => item.blockName + '>' + item.dateEnd)}
              borderWidth={6}
              tooltipFormatter={(params) => {
                // @ts-expect-error - TS doesn't know that params is an object
                const { name, value } = params[0]
                const dateString = formatISO_(name.split('>')[1], 'dd MMM yyyy')
                const valueWithoutDateString = name.split('>')[0] || name
                const [, , , mean, ,] = value
                return `<b>${valueWithoutDateString}</b><br />${dateString}<br/><div style="border-bottom: 1px solid grey; margin: 8px 0px"></div>${t('mean')}: ${formatToSingleDecimalIfNecessary(mean)}`
              }}
              yAxisCategoryLabelFormatter={labelFormatter_Land}
            />
          </Box>
        )}
      </SummaryTileWrapper>
    </>
  )
}

export default BowPrunedSection
