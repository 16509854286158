import * as d3 from 'd3'
import { CSSProperties, useEffect, useMemo, useRef } from 'react'

type BarcodedRowsProps = {
  rowsStartNumber: number
  rowsEndNumber: number
  rowsScanned: number[]
  color?: string
  style?: CSSProperties
}

const BarcodedRows = ({ rowsStartNumber, rowsEndNumber, rowsScanned, color = '#64748B', style }: BarcodedRowsProps) => {
  const svgRef = useRef<SVGSVGElement>(null)

  const width = 240
  const range = Math.max(rowsEndNumber - rowsStartNumber + 1, 1) // Ensure range is at least 1
  const normalisedRowsLength = range === rowsScanned.length ? 1 : range > 50 ? Math.floor(range / 3) : Math.max(range, 1) // Ensure normalisedRowsLength is at least 1
  const rowHeight = 30

  const normalisedRows = useMemo(() => {
    const normaliseRows = (rowsStartNumber: number, rowsEndNumber: number, rowsFilled: number[]) => {
      if (rowsEndNumber < rowsStartNumber || rowsEndNumber === 0 || rowsStartNumber === rowsEndNumber) {
        console.warn('Invalid rowsStartNumber or rowsEndNumber', rowsStartNumber, rowsEndNumber)
        return new Array(normalisedRowsLength).fill(false)
      }

      const range = Math.max(rowsEndNumber - rowsStartNumber, 1) // Ensure range is at least 1
      const normalizedRows: boolean[] = new Array(normalisedRowsLength).fill(false)

      rowsFilled.forEach((row) => {
        const normalizedRow = Math.floor(((row - rowsStartNumber) / range) * (normalisedRowsLength - 1))
        normalizedRows[normalizedRow] = true
      })

      return normalizedRows
    }

    return normaliseRows(rowsStartNumber, rowsEndNumber, rowsScanned)
  }, [rowsStartNumber, rowsEndNumber, rowsScanned, normalisedRowsLength])

  useEffect(() => {
    const svg = d3.select(svgRef.current)

    // Clear existing content
    svg.selectAll('*').remove()

    // Using normalised rows create a barcode
    const rowWidth = width / normalisedRowsLength || 1
    svg
      .attr('width', rowWidth * normalisedRowsLength)
      .attr('height', rowHeight)
      .attr('fill', 'white')

    svg
      .selectAll('rect')
      .data(normalisedRows)
      .enter()
      .append('rect')
      .attr('x', (_d, i) => i * rowWidth)
      .attr('y', 0)
      .attr('width', rowWidth)
      .attr('height', rowHeight)
      .style('fill', (d) => (d ? color : ''))
  }, [normalisedRows, normalisedRowsLength])

  // const length = rowsScanned.length

  return (
    <div style={{ border: `2px solid ${color}`, height: rowHeight + 2, width: width + 2, backgroundColor: '#FFFFFF', ...style }}>
      <svg ref={svgRef}></svg>
    </div>
  )
}

export default BarcodedRows
