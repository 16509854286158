import HighlightsInfoWrapper from '@components/containers/HighlightsInfoWrapper'
import SummaryPageWrapper from '@components/containers/SummaryPageWrapper'
import ConditionalStatsTileContent from '@components/sections/ConditionalStatsTileContent'
import HealthFiltersSection from '@components/sections/health-summary/HealthFiltersSection'
import HealthTable from '@components/sections/health-summary/HealthTable'
import { DiseaseReportsByBlock, useGetDiseaseBlockReports } from '@hooks/health/useGetDiseaseBlockReports'
import Box from '@mui/material/Box'
import { DiseaseStats } from '@typings/dtos/block-report/models'
import { HealthBlockReport_SortOptions } from '@utils/options'
import { useEffect, useState } from 'react'

const HealthSummaryPage = () => {
  const [data, setData] = useState<DiseaseReportsByBlock[]>([])
  const [showDiseases, setShowDiseases] = useState<Array<keyof DiseaseStats>>(['pmTotalInfectedVines', 'bbrInfectedVines'])
  const [sort, setSort] = useState<string>(HealthBlockReport_SortOptions[0]?.value!)

  const { diseaseReports, isFetching } = useGetDiseaseBlockReports()

  useEffect(() => {
    if (!diseaseReports) setData([])
    else if (diseaseReports.length === 1) setData(diseaseReports)
    else if (sort === 'desc-high-detection-count' || sort === 'asc-high-detection-count') {
      const _data = [...diseaseReports].sort((a, b) => {
        const recentReportA = a.reports.at(-1)
        const recentReportB = b.reports.at(-1)
        if (!recentReportA || !recentReportB) return 0

        const sumA = showDiseases.reduce((acc, key) => {
          if (recentReportA.blockData[key]) return acc + recentReportA.blockData[key]
          return acc
        }, 0)

        const sumB = showDiseases.reduce((acc, key) => {
          if (recentReportB.blockData[key]) return acc + recentReportB.blockData[key]
          return acc
        }, 0)

        if (sort.startsWith('asc')) return sumA - sumB
        else return sumB - sumA
      })
      return setData(_data)
    } else {
      const _data = [...diseaseReports].sort((a, b) => {
        if (sort === 'desc-vineyard-name') return a.vineyardName.localeCompare(b.vineyardName)
        else return b.vineyardName.localeCompare(a.vineyardName)
      })
      return setData(_data)
    }
  }, [diseaseReports, sort, showDiseases])

  return (
    <SummaryPageWrapper dataLength={data.length} isLoading={isFetching}>
      <Box display="flex" gap={2} flexWrap="wrap">
        <HighlightsInfoWrapper
          label="showing_stats_for"
          mainHighlightComponent={<ConditionalStatsTileContent data={data} />}
          tooltipInfo="pruning_page.summary.tooltip_showing_stats_for"
        />
      </Box>
      <HealthFiltersSection showDiseases={showDiseases} setShowDiseases={setShowDiseases} sort={sort} setSort={setSort} />

      {data.length ? (
        <Box display="grid" gap={2} pb={4}>
          {data.map((block) => (
            <HealthTable key={block.blockId} block={block} detectionsToShow={showDiseases} />
          ))}
        </Box>
      ) : null}
    </SummaryPageWrapper>
  )
}

export default HealthSummaryPage
