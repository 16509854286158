import HighlightsInfoWrapper from '@components/containers/HighlightsInfoWrapper'
import SummaryPageWrapper from '@components/containers/SummaryPageWrapper'
import ConditionalStatsTileContent from '@components/sections/ConditionalStatsTileContent'
import YieldFiltersSection from '@components/sections/yield-summary/YieldFiltersSection'
import YieldTable from '@components/sections/yield-summary/YieldTable'
import YieldTableDialog from '@components/sections/yield-summary/YieldTableDialog'
import { YieldReportsByBlock, useGetYieldBlockDataApi } from '@hooks/useGetYieldBlockDataApi'
import { YieldBlockReport_SortOptions } from '@utils/options'
import { compareAsc } from 'date-fns'
import { useState } from 'react'
import 'simplebar-react/dist/simplebar.min.css'

const YieldSummaryPage = () => {
  const [open, setOpen] = useState(false)
  const [selectedScan, setSelectedScan] = useState<{ block: YieldReportsByBlock; scanIndex: number }>()

  const { block, scanIndex } = selectedScan || {}

  const [sortOption, setSortOption] = useState<{ label: string; value: string }>(
    YieldBlockReport_SortOptions[0] as { label: string; value: string }
  )

  const sortItems = (items: YieldReportsByBlock[], selectedSortOption: string) => {
    // Calculate the most recent scan for each item
    const itemsWithRecentScan = items.map((item) => {
      const mostRecentScan = item.reports.reduce((prev, current) => {
        return compareAsc(new Date(prev.endDate), new Date(current.endDate)) > 0 ? prev : current
      })
      return { ...item, mostRecentScan }
    })

    // Sort the items based on the selected sort option and the value in the most recent scan
    switch (selectedSortOption) {
      case 'desc-visible':
        return itemsWithRecentScan.sort((a, b) => b.mostRecentScan.visBunchesPerVineMean - a.mostRecentScan.visBunchesPerVineMean)
      case 'asc-visible':
        return itemsWithRecentScan.sort((a, b) => a.mostRecentScan.visBunchesPerVineMean - b.mostRecentScan.visBunchesPerVineMean)
      case 'desc-corrected':
        return itemsWithRecentScan.sort(
          (a, b) => (b.mostRecentScan.correctedBunchesPerVineMean ?? 0) - (a.mostRecentScan.correctedBunchesPerVineMean ?? 0)
        )
      case 'asc-corrected':
        return itemsWithRecentScan.sort(
          (a, b) => (a.mostRecentScan.correctedBunchesPerVineMean ?? 0) - (b.mostRecentScan.correctedBunchesPerVineMean ?? 0)
        )
      case 'desc-vineyard-name':
        return items.sort((a, b) => a.vineyardName.localeCompare(b.vineyardName))
      case 'asc-vineyard-name':
        return items.sort((a, b) => b.vineyardName.localeCompare(a.vineyardName))
      default:
        return items
    }
  }

  const { data, varietyOptions, isFetching } = useGetYieldBlockDataApi()

  return (
    <SummaryPageWrapper dataLength={data.length} isLoading={isFetching}>
      <HighlightsInfoWrapper
        label="showing_stats_for"
        mainHighlightComponent={<ConditionalStatsTileContent data={data} />}
        tooltipInfo="pruning_page.summary.tooltip_showing_stats_for"
      />
      <YieldFiltersSection
        sort={sortOption.value}
        setSort={(sort) =>
          setSortOption(
            YieldBlockReport_SortOptions.find((opt) => opt.value === sort) || ({} as { label: string; value: string })
          )
        }
      />

      {varietyOptions.map((variety) => (
        <div key={variety.value}>
          <span
            title="Variety"
            style={{
              display: 'flex',
              textTransform: 'uppercase',
              width: '100%',
              background: 'linear-gradient(90deg, #262626 0%, #FFFFFF 100%)',
              color: 'white',
              borderRadius: 4,
              fontSize: 13,
              padding: '5px 10px',
              gap: 5,
              letterSpacing: 0.5,
              fontWeight: 500,
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024">
              <path
                fill="white"
                d="M544 195.2a160 160 0 0 1 96 60.8a160 160 0 1 1 146.24 254.976a160 160 0 0 1-128 224a160 160 0 1 1-292.48 0a160 160 0 0 1-128-224A160 160 0 1 1 384 256a160 160 0 0 1 96-60.8V128h-64a32 32 0 0 1 0-64h192a32 32 0 0 1 0 64h-64zM512 448a96 96 0 1 0 0-192a96 96 0 0 0 0 192m-256 0a96 96 0 1 0 0-192a96 96 0 0 0 0 192m128 224a96 96 0 1 0 0-192a96 96 0 0 0 0 192m128 224a96 96 0 1 0 0-192a96 96 0 0 0 0 192m128-224a96 96 0 1 0 0-192a96 96 0 0 0 0 192m128-224a96 96 0 1 0 0-192a96 96 0 0 0 0 192"
              />
            </svg>
            {variety.label}
          </span>
          <div style={{ display: 'grid', gap: '2rem', padding: '8px 0px 8px 0px' }}>
            {sortItems(
              data.filter((block) => block.varietyId === variety.value),
              sortOption.value
            ).map((block) => {
              return <YieldTable key={block.blockId} block={block} setSelectedScan={setSelectedScan} setOpen={setOpen} />
            })}
          </div>
        </div>
      ))}

      <YieldTableDialog
        open={open}
        handleClose={() => setOpen(false)}
        block={block}
        scanIndex={scanIndex}
        setSelectedScan={setSelectedScan}
      />
    </SummaryPageWrapper>
  )
}

export default YieldSummaryPage
