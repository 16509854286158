import SpatialReference from '@arcgis/core/geometry/SpatialReference'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer'
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol'
import { VISUAL_VARIABLES } from '../constants'

const renderer = new UniqueValueRenderer({
  field: 'renderType',
  field2: 'haveImages',
  fieldDelimiter: ', ',
  uniqueValueInfos: [
    {
      value: 'purple, false',
      symbol: new SimpleMarkerSymbol({ color: '#A855F7' }),
    },
    {
      value: 'purple, true',
      symbol: new SimpleMarkerSymbol({ color: '#A855F7', outline: { color: '#FFF', width: 1.5 } }),
    },
    {
      value: 'blue, false',
      symbol: new SimpleMarkerSymbol({ color: '#FFA500' }),
    },
    {
      value: 'blue, true',
      symbol: new SimpleMarkerSymbol({ color: '#FFA500', outline: { color: '#FFF', width: 1.5 } }),
    },
    {
      value: 'red, false',
      symbol: new SimpleMarkerSymbol({ color: '#FF0000' }),
    },
    {
      value: 'red, true',
      symbol: new SimpleMarkerSymbol({ color: '#FF0000', outline: { color: '#FFF', width: 1.5 } }),
    },
  ],
  visualVariables: VISUAL_VARIABLES,
})

export const vinesLayer = new FeatureLayer({
  id: 'vines-layer',
  title: 'Vines Layer',
  source: [],
  fields: [
    { name: 'id', type: 'integer' },
    { name: 'renderType', type: 'string' },
    { name: 'haveImages', type: 'string' },
    { name: 'vine', type: 'string' },
  ],
  objectIdField: 'objectId',
  geometryType: 'point',
  outFields: ['id', 'vine'],
  spatialReference: SpatialReference.WGS84,
  renderer: renderer,
  popupEnabled: true,
  popupTemplate: {},
})
