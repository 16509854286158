import { addBlocks, highlightMultipleBlocksById, moveViewToSelectedBlocks } from '@/arcgis/controllers'
import { initialiseMap } from '@/arcgis/webmap'
import Graphic from '@arcgis/core/Graphic'
import Map from '@arcgis/core/Map'
import MapView from '@arcgis/core/views/MapView'
import useAuthStore from '@stores/auth'
import useVineyardStore from '@stores/vineyard'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import ScanRecencyToggle from './ScanRecencyToggle'

type MapBaseProps = {
  children: ReactNode
  onGraphicClick: (graphic: Graphic) => void
  base: (params: { map: Map; view: MapView; isLoaded: boolean }) => void
  height?: string
}

const MapBase = ({ children, onGraphicClick, base, height = 'calc(100dvh - 5rem)' }: MapBaseProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [mapView, setMapView] = useState<__esri.MapView | null>(null)
  const [graphicState, setGraphicState] = useState<Graphic | null>(null)
  // const [timeClicked, setTimeClicked] = useState<number>(0)
  const [isMapLoaded, setIsMapLoaded] = useState(false)

  const [vineyards, blocks, selectedBlocks, selectedBlocksLocal, setSelectedBlocksLocal] = useVineyardStore(
    useShallow((s) => [s.vineyards, s.blocks, s.selectedBlocks, s.selectedBlocksLocal, s.setSelectedBlocksLocal])
  )
  const [user] = useAuthStore(useShallow((s) => [s.user]))

  useEffect(() => {
    if (!user) return
    if (ref.current === null && mapView) return

    const coordinates = user.location?.coordinates

    if (ref.current !== null && !mapView) {
      initialiseMap({
        center: coordinates,
        container: ref.current,
        onGraphicClick: (graphic) => {
          onGraphicClick(graphic)
          setGraphicState(graphic)
          // setTimeClicked(Date.now())
        },
      })
        .then(({ map, mapView }) => {
          setMapView(mapView)
          base({ map, view: mapView, isLoaded: true })
          mapView.when(() => setIsMapLoaded(true))
        })
        .catch((error) => console.error('Map initialization failed: ', error))
    }
  }, [mapView, vineyards, blocks, user?.location])

  // Add or remove blocks from the selectedBlocksLocal state using the clicked esri graphic
  useEffect(() => {
    if (!graphicState) return
    if (graphicState.layer.id === 'block-layer' || graphicState.layer.id === 'block-highlight-layer') {
      const blockId = graphicState.attributes.id
      const block = blocks.find((block) => block.id === blockId)
      const blockIndex = selectedBlocksLocal.findIndex((block) => block.id === blockId)

      if (block && blockIndex === -1) setSelectedBlocksLocal([...selectedBlocksLocal, block])
      else setSelectedBlocksLocal(selectedBlocksLocal.filter((block) => block.id !== blockId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphicState, blocks])
  // }, [graphicState, blocks, timeClicked])

  useEffect(() => {
    if (mapView) {
      // addVineyards(vineyards)
      addBlocks(blocks, selectedBlocks)
    }
  }, [blocks, mapView, selectedBlocks])

  useEffect(() => {
    if (mapView) {
      mapView.when(() => {
        const blockIds = selectedBlocksLocal.map((block) => block.id)
        moveViewToSelectedBlocks(blockIds, mapView)
      })
    }
  }, [mapView, selectedBlocks])

  useEffect(() => {
    if (mapView) {
      mapView.when(() => {
        const blockIds = selectedBlocksLocal.map((block) => block.id)
        highlightMultipleBlocksById(blockIds)
      })
    }
  }, [mapView, selectedBlocksLocal])

  console.log('MapBase rendered')

  return (
    <div style={{ position: 'relative', backgroundColor: '#000000' }}>
      <div ref={ref} style={{ height, width: '100%' }} />
      {isMapLoaded && <ScanRecencyToggle />}
      {children}
    </div>
  )
}

export default MapBase
